import { Spinner } from "@fluentui/react";
import React from "react";

import "!style-loader!css-loader!./SSOPage.css";

const SSOPage = ({ api_provider, sso, setSso, onSso, onError }) => {

    const pollInterval = setInterval(async () => {
        let progress = await api_provider.get(`user/sso-sign-in-progress?state=${sso}`);
        if (progress.progress === 1) {
            if (progress.error) {
                onError(progress.error)
            } else {
                onSso(progress.auth_token)
            }
            setSso("");
            clearInterval(pollInterval)
        }
    },
        5000
    )

    const cancelSsoHandler = () => {
        clearInterval(pollInterval)
        setSso("")
    }

    return (
        <div className="full-width">
            <div className="text sso-margin">YOU ARE BEING REDIRECTED...</div>
            <Spinner size={3} />
            <button className="big-button sso-margin" onClick={cancelSsoHandler}>CANCEL</button>
        </div>
    )
}

export default SSOPage;