import ApiProvider from '../api/ApiProvider';
import ReportProvider from '../api/ReportProvider';
import * as React from "react";
import { useState, useEffect } from "react";
import TfaPage from './components/TfaPage';
import LoginPage from './components/LoginPage';
import ErrorPage from './components/ErrorPage';
import MyAccount from './components/MyAccount';
import SSOPage from './components/SSOPage';
import FirstRunPage from './components/FirstRunPage';
import { Spinner } from '@fluentui/react';
import DataStore from "../utils/DataStore";

import "!style-loader!css-loader!./App.css";
// TODO: .env file
// const API_ENDPOINT = "https://web4-dot-syft-239908.ew.r.appspot.com/api/v1";
// const API_ENDPOINT = "https://web2-dot-syft-239908.ew.r.appspot.com/api/v1";
// const API_ENDPOINT = "https://syft-239908.appspot.com/api/v1";
const API_ENDPOINT = "https://www.syftanalytics.com/api/v1";
// const API_ENDPOINT = "https://localhost:8001/api/v1";

const VERSION = "1.1.2";

let entities;
const api_provider = new ApiProvider(API_ENDPOINT);
let initialLoad: boolean = false;

const versionCompare = (currentVersion: string, localVersion: string) => {
    if (!currentVersion) {
        return false;
    } else {
        const semver = require("semver");
        return semver.gt(currentVersion, localVersion);
    }
}

const App: React.FC = () => {

    // TODO: clear web cache event
    const [disconnected, setDisconnected] = useState<boolean>(false);
    const [versionContent, setVersionContent] = useState<JSX.Element>(<div className="text" >{`version ${VERSION}`}</div>);
    const [loginStatus, setLoginStatus] = useState(null);
    const [isError, setIsError] = useState(null);
    const [requireTfa, setRequireTfa] = useState<boolean>(false);
    const [suspiciousActivity, setSuspiciousActivity] = useState<boolean>(false);
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [keepMeLoggedIn, setKeepMeLoggedIn] = useState<boolean>(true);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [sso, setSso] = useState<string>("");
    const [outdated, setOutdated] = useState<string>("");
    const [firstRun, setFirstRun] = useState<boolean>(false);

    if (outdated && !isError) {
        setIsError("Your excel add-in is outdated, please update");
        setLoginStatus(null)
    }

    if (disconnected && !isError) {
        setIsError("Unable to connect to the server, please check your internet connection and try again.");
    }

    useEffect(() => {
        const checkLoginStatus = async () => {
            // DataStore.removeAllKeys();
            try {
                const resources = await DataStore.getResources(); // try access locally stored resources
                entities = resources.entities;
                if (resources.loginResult.keep_me_logged_in) { // if keep me logged in was selected in the previous session, check if the stored auth token is still valid
                    const authTokenTestResponse = await api_provider.post("user/validate-auth-token", { auth_token: resources.loginResult.auth_token })
                    if (authTokenTestResponse.result) {
                        authApiProvider(resources.loginResult.auth_token);
                        setLoginStatus(resources.loginResult); // if the auth token is valid, set the login status to truthy.
                    }
                    else {
                        DataStore.removeResources();
                    }
                } else {
                    DataStore.removeResources();
                }
            }
            catch (error) {
                if (error.status + "" === "403") {
                    DataStore.removeResources();
                }
            }
            finally {
                if (initialLoad) { setIsLoaded(true) } else { initialLoad = true }
            }
        }
        const checkFirstRun = async () => {
            const firstRun = await DataStore.getFirstRunStore();
            setFirstRun(firstRun);
        }
        checkLoginStatus();
        checkFirstRun();
    }, [])

    useEffect(() => {
        const checkForUpdate = async () => {
            let storeLink;
            let currentVersion;
            let minVersion;
            let versionResponse;
            try {
                versionResponse = await api_provider.get("status/excel-addin-version")
            } catch (error) {
                if (!disconnected) { setDisconnected(true) };
            } finally {
                if (versionResponse) {
                    currentVersion = versionResponse["current_version"];
                    storeLink = versionResponse["store_link"];
                    minVersion = versionResponse["min_version"];
                    if (versionCompare(minVersion, VERSION)) {
                        setOutdated(storeLink);
                    }
                }
                const updateAvailable = versionCompare(currentVersion, VERSION);
                if (updateAvailable) {
                    setVersionContent(<div className="text version" >{`version ${VERSION} - `}<a className="link" href={storeLink}>update now</a></div>)
                }
                if (initialLoad) { setIsLoaded(true) } else { initialLoad = true }
            }
        }
        checkForUpdate();
    }, [disconnected])

    const checkExcelAccess = async (email) => {
        let excel_access_result = await api_provider.post("user/excel-access", { // checks if both the user's org and user's role permissions allow access to this ass-on
            email: email,
        });
        return {
            access: excel_access_result.has_add_on_access,
            message: excel_access_result.message,
            entities: excel_access_result.entities ? excel_access_result.entities : [],
        }
    }

    const checkTfa = async () => {
        try{
            const two_factor_result = await api_provider.post("user/two-factor", {
                email: username,
            });
    
            if (
                two_factor_result.two_factor_enabled &&
                two_factor_result.two_factor_set
            ) {
                setRequireTfa(true);
                setIsLoaded(true);
            } else {
                login();
            }
        } catch(error) {
            setIsLoaded(true);
            setIsError("Something went wrong, please clear your cache and try again.");
        }
    }

    const login = async (tfaToken = "", email_validation_code = "") => {
        try {
            let loginResult = await api_provider.post("user/log-in", {
                email: username,
                password: password,
                two_factor_token: tfaToken,
                keep_me_logged_in: keepMeLoggedIn,
                email_validation_code: email_validation_code,
            });

            if (loginResult.auth_token) {
                const excelAccessResult = await checkExcelAccess(username);
                if (excelAccessResult.access) {
                    authApiProvider(loginResult.auth_token);
                    await storeResources({ ...loginResult, email: username, keep_me_logged_in: keepMeLoggedIn, allowedEntityIDs: excelAccessResult.entities })
                }
                else {
                    setIsError(excelAccessResult.message)
                }
            } else {
                setIsError(loginResult);
            }
            setPassword("");
        }
        catch (error) {
            if (error === "suspicious_activity") {
                setSuspiciousActivity(true);
                setIsError("We noticed a login to your account from a new location or device. To protect your account and data, we've emailed you a verification code.");
            } else {
                setIsError(error);
                setPassword("");
            }
        }
        finally {
            setIsLoaded(true);
        }
    }

    const storeResources = async (loginResult) => {
        setLoginStatus(loginResult);
        let report_provider = new ReportProvider(api_provider);

        let entity_report_result = await report_provider.getReport({
            name: "companies",
            params: {},
        });

        const allowedEntityIDs = loginResult.allowedEntityIDs;
        const allOrgEntities = entity_report_result.companies;

        if (allowedEntityIDs.length > 0) {
            entities = allOrgEntities.filter(value => loginResult.allowedEntityIDs.includes(value.id));
        }
        else {
            entities = allOrgEntities;
        }

        const entitiesWithConfigs = [];
        await Promise.all(entities.map(async (entity) => {
            const fourwayForecastResult = await api_provider.post("company/list-four-way-forecasts", { company_id: entity.global_id });
            const buildPNLResult = await api_provider.post("company_resource/get-build-pnl", { company_id: entity.global_id });
            const buildBSResult = await api_provider.post("company_resource/get-build-bs", { company_id: entity.global_id });
            entitiesWithConfigs.push({ ...entity, fourway_forecast: fourwayForecastResult, build_pnl: Object.values(Object.values(buildPNLResult)[0]), build_bs: Object.values(Object.values(buildBSResult)[0]) });
        })); // repopulate entities with fourway forecast, build pnl and build bs options
        entities = entitiesWithConfigs;
        entities.sort((a, b) => a.name.localeCompare(b.name));
        await DataStore.setResources(JSON.stringify({ endpoint: API_ENDPOINT, loginResult: loginResult, entities: entities }));
    }

    const ssoResponseHandler = async (auth_token: string) => {
        setIsLoaded(false);
        authApiProvider(auth_token);

        const ssoDetails = JSON.parse(window.atob(auth_token.split(".")[1])); // decode middle segment of jwt auth token to get user ID

        const userDetails = await api_provider.post("user/intercom-user-details", { user_id: ssoDetails.user_id });
        setUsername(userDetails.email);

        const user_name = userDetails.name.split(" ")
        const first_name = user_name[0];
        const last_name = user_name.slice(1).join(" ");

        const excel_access = await checkExcelAccess(userDetails.email);
        if (excel_access.access) {
            authApiProvider(auth_token);
            await storeResources({
                ...userDetails,
                auth_token: auth_token,
                plan: userDetails.company.plan,
                first_name: first_name,
                last_name: last_name,
                keep_me_logged_in: keepMeLoggedIn,
                allowedEntityIDs: excel_access.entities
            });
        }
        else {
            setIsError(excel_access.message)
        }
        setIsLoaded(true)
    }

    const authApiProvider = (auth_token: string) => {
        let auth_token_generator;

        auth_token_generator = {
            getAuthToken: () => auth_token,
        };
        api_provider.setAuthTokenGenerator(auth_token_generator);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoaded(false);
        checkTfa();
    }

    const submitTfaHandler = async (tfaToken) => {
        setRequireTfa(false);
        setIsLoaded(false);
        await login(tfaToken);
    }

    const submitReauthenticationHandler = async (verificationToken) => {
        setSuspiciousActivity(false);
        setRequireTfa(false);
        setIsLoaded(false);
        await login("", verificationToken);
    }

    const setReauthenticationHandler = async () => {
        setIsError("");
        setRequireTfa(true);
    }


    const logoutHandler = async () => {
        await DataStore.removeAllResources();
        setLoginStatus(null);
    }

    const setFirstRunHandler = () => {
        setFirstRun(false);
        DataStore.setFirstRunStore();
    }

    const setIsErrorHandler = (error) => {
        setIsError(error);
        if (disconnected && error === null) {
            setIsLoaded(false)
            setTimeout(() => setDisconnected(false), 500)
        }
    }

    const setSsoHandler = (ssoState: string) => {
        setSso(ssoState);
    }

    const setUsernameHandler = (username: string) => {
        setUsername(username);
    }

    const setPasswordHandler = (password: string) => {
        setPassword(password);
    }

    const setKeepMeLoggedInHandler = () => {
        setKeepMeLoggedIn(prevState => !prevState)
    }

    let content;
    if (firstRun) {
        content = <FirstRunPage setFirstRunHandler={setFirstRunHandler} />
    } else if (isError || disconnected) {
        content = <ErrorPage isError={isError} setIsError={setIsErrorHandler} outdated={outdated} suspiciousActivity={suspiciousActivity} setRequireReauthentication={setReauthenticationHandler} />
    } else if (requireTfa) {
        content = <TfaPage onSubmitTfa={submitTfaHandler} />
        if (suspiciousActivity) {
            content = <TfaPage onSubmitTfa={submitReauthenticationHandler} />
        }
    } else if (loginStatus) {
        content = <MyAccount apiProvider={api_provider} loginStatus={loginStatus} entities={entities} onError={setIsErrorHandler} onLogout={logoutHandler} />
    } else if (sso) {
        content = <SSOPage api_provider={api_provider} sso={sso} setSso={setSsoHandler} onSso={ssoResponseHandler} onError={setIsErrorHandler} />
    } else {
        content = <LoginPage endpoint={API_ENDPOINT} apiProvider={api_provider} username={username} setUsername={setUsernameHandler} password={password} setPassword={setPasswordHandler} keepMeLoggedIn={keepMeLoggedIn} setKeepMeLoggedIn={setKeepMeLoggedInHandler} submitHandler={submitHandler} setSso={setSsoHandler} />
    }

    return (
        <div className="side-panel-wrapper">
            {(!isLoaded || sso || isError && !firstRun) ? <div></div> : null}
            {isLoaded ? content : <div><Spinner size={3} /></div>}
            {!firstRun ? versionContent : !isLoaded && <div></div>}
        </div>
    );
}

export default App;