import React, { useRef, useState } from "react";

import "!style-loader!css-loader!./TfaPage.css";
import { Spinner } from "@fluentui/react";

const TfaPage: React.FC<{ onSubmitTfa: (tfaToken: string) => void }> = ({ onSubmitTfa }) => {
    const [state, setState] = useState({
        code_1: "",
        code_2: "",
        code_3: "",
        code_4: "",
        code_5: "",
        code_6: "",
    });

    const code_1 = useRef(null);
    const code_2 = useRef(null);
    const code_3 = useRef(null);
    const code_4 = useRef(null);
    const code_5 = useRef(null);
    const code_6 = useRef(null);

    let tfaToken;

    if (Object.values(state).filter(value => value === "").length === 0) {
        tfaToken = state.code_1 + state.code_2 + state.code_3 + state.code_4 + state.code_5 + state.code_6;
        onSubmitTfa(tfaToken);
    }

    let content = (
        <div className="two-factor-code-wrapper">
            <div className="two-factor-code-heading">
                Enter the authentication code
            </div>

            <div className="two-factor-code-segment">
                <input
                    ref={code_1}
                    type="text"
                    className="two-factor-code-input"
                    placeholder=""
                    value={state["code_1"]}
                    autoFocus
                    onChange={event => {
                        var val = event.target.value.replace("-", "");
                        if (!/^\d*$/.test(val)) {
                            return;
                        }
                        if (val.length <= 1) {
                            setState(prevState => { return { ...prevState, code_1: val } });
                        }
                        if (val.length === 1) {
                            code_2.current.focus();
                        }
                        if (val.length === 6) {
                            setState({
                                code_1: val[0],
                                code_2: val[1],
                                code_3: val[2],
                                code_4: val[3],
                                code_5: val[4],
                                code_6: val[5]
                            });
                            code_1.current.blur();
                        }
                    }}
                />
                <input
                    onKeyDown={event => {
                        var key = event.keyCode || event.charCode;
                        if (key === 8 && !state.code_2) {
                            code_1.current.focus();
                        }
                    }}
                    ref={code_2}
                    type="text"
                    className="two-factor-code-input"
                    placeholder=""
                    value={state.code_2}
                    onChange={event => {
                        if (!/^\d*$/.test(event.target.value)) {
                            return;
                        }
                        if (event.target.value.length <= 1) {
                            setState(prevState => { return { ...prevState, code_2: event.target.value } });
                        }
                        if (event.target.value.length === 1) {
                            code_3.current.focus();
                        }
                    }}
                />
                <input
                    onKeyDown={event => {
                        var key = event.keyCode || event.charCode;

                        if (key === 8 && !state.code_3) {
                            code_2.current.focus();
                        }
                    }}
                    ref={code_3}
                    type="text"
                    className="two-factor-code-input"
                    placeholder=""
                    value={state.code_3}
                    onChange={event => {
                        if (!/^\d*$/.test(event.target.value)) {
                            return;
                        }
                        if (event.target.value.length <= 1) {
                            setState(prevState => { return { ...prevState, code_3: event.target.value } });
                        }
                        if (event.target.value.length === 1) {
                            code_4.current.focus();
                        }
                    }}
                />
            </div>
            <div className="two-factor-code-hyphen">
                -
            </div>
            <div className="two-factor-code-segment">
                <input
                    onKeyDown={event => {
                        var key = event.keyCode || event.charCode;

                        if (key === 8 && !state.code_4) {
                            code_3.current.focus();
                        }
                    }}
                    ref={code_4}
                    type="text"
                    className="two-factor-code-input"
                    placeholder=""
                    value={state.code_4}
                    onChange={event => {
                        if (!/^\d*$/.test(event.target.value)) {
                            return;
                        }
                        if (event.target.value.length <= 1) {
                            setState(prevState => { return { ...prevState, code_4: event.target.value } });
                        }
                        if (event.target.value.length === 1) {
                            code_5.current.focus();
                        }
                    }}
                />
                <input
                    onKeyDown={event => {
                        var key = event.keyCode || event.charCode;
                        if (key === 8 && !state.code_5) {
                            code_4.current.focus();
                        }
                    }}
                    ref={code_5}
                    type="text"
                    className="two-factor-code-input"
                    placeholder=""
                    value={state.code_5}
                    onChange={event => {
                        if (!/^\d*$/.test(event.target.value)) {
                            return;
                        }

                        if (event.target.value.length <= 1) {
                            setState(prevState => { return { ...prevState, code_5: event.target.value } });
                        }
                        if (event.target.value.length === 1) {
                            code_6.current.focus();
                        }
                    }}
                />
                <input
                    onKeyDown={event => {
                        var key = event.keyCode || event.charCode;
                        if (key === 8 && !state.code_6) {
                            code_5.current.focus();
                        }
                    }}
                    ref={code_6}
                    type="text"
                    className="two-factor-code-input"
                    placeholder=""
                    value={state.code_6}
                    onChange={event => {
                        if (!/^\d*$/.test(event.target.value)) {
                            return;
                        }
                        if (event.target.value.length <= 1) {
                            setState(prevState => { return { ...prevState, code_6: event.target.value } });
                        }
                        if (event.target.value.length === 1) {
                            code_6.current.blur();
                        }
                    }}
                />
            </div>
        </div>
    )

    if (tfaToken) {
        content = <Spinner size={3} />
    }

    return (
        content
    )
}



export default TfaPage;