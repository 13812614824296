import React, { ReactElement, useEffect, useState } from "react";
import Select from "react-select";

import "!style-loader!css-loader!./MyAccount.css";
import DataStore from "../../utils/DataStore";
import { IVL, IMyAccount, IEntityVL } from "../../utils/InterfaceStore";

const plansMapping = {
    basic: "Basic",
    trial: "Trial",
    essential_unlimited: "Essential Unlimited",
    professional_unlimited: "Professional Unlimited",
    essential_individual: "Essential Individual",
    professional_individual: "Professional Individual",
    enterprise_plus_unlimited: "Enterprise Plus Unlimited",
    enterprise_plus_individual: "Enterprise Plus Individual",
    outstanding_payment: "Outstanding Payment",
    demo: "Demo"
}

const refreshCells = async () => {
    await Excel.run(async (context) => {
        let sheet = context.workbook.worksheets.getActiveWorksheet();
        let usedRange = sheet.getUsedRange();
        usedRange.setDirty();
        await context.sync();
    });
}

let refreshButtonStyle = { "--p": "0%" } as React.CSSProperties;

const keysToVLs = (obj: {}): IVL[] => {
    if (obj) {
        return Object.keys(obj).map(key => { return { value: key, label: key } as IVL });
    }
    return [];
}

const MyAccount: React.FC<IMyAccount> = ({ apiProvider, loginStatus, entities, onError, onLogout }) => {
    const refreshOptions = (entities.filter(entity => ["xero", "sage-one-accounting-sa", "quickbooks"].includes(entity.accounting_provider_name)).map(entity => { return { value: entity.id, label: entity.name } }) as IEntityVL[]).sort((a, b) => a.label.localeCompare(b.label));
    const entityOptions = (entities.map(entity => { return { value: entity.id, label: entity.name } }) as IEntityVL[]).sort((a, b) => a.label.localeCompare(b.label));
    const [selectedEntity, setSelectedEntity] = useState<IEntityVL>(entityOptions[0]);
    const [refreshEntity, setRefreshEntity] = useState<IEntityVL>(refreshOptions[0]);
    const [refreshingEntities, setRefreshingEntities] = useState<IEntityVL[]>([]);
    const [settingConfigs, setSettingConfigs] = useState<boolean>(false);

    // console.log("My account", entities);
    const selectedEntityData = (e?: IEntityVL) => {
        if (e) {
            return entities.find(entity => entity.id === e.value);
        }
        else {
            return entities.find(entity => entity.id === selectedEntity.value)
        }
    }

    const getLayouts = (entity: IEntityVL, layout: string) => {
        let attributeNames = [];
        if (layout === "bs") {
            attributeNames = ["balance_sheet_custom_layouts", "default_bs_layout"];
        } 
        else if (layout === "pnl") {
            attributeNames = ["custom_layouts", "default_pl_layout"];
        }

        try {
            const options = keysToVLs({ ...selectedEntityData(entity)[attributeNames[0]], ...selectedEntityData(entity)[attributeNames[1]] });
            options.sort((a, b) => a.label.localeCompare(b.label));
            return options;
        }
        catch {
            return []
        }
    }

    const getOptions = (entity: IEntityVL, report: string) => {
        let attributeName = "";
        if (report === "bs") {
            attributeName = "build_bs";
        }
        else if (report === "pnl") {
            attributeName = "build_pnl";
        }
        else if (report === "forecast") {
            attributeName = "fourway_forecast";
        }

        try {
            const options = selectedEntityData(entity)[attributeName].map(value => { return { value: value.name, label: value.name } });
            options.sort((a, b) => a.label.localeCompare(b.label));
            return options as IVL[];
        }
        catch {
            return []
        }
    }

    const bsLayoutOptions = getLayouts(selectedEntity,"bs");
    const pnlLayoutOptions = getLayouts(selectedEntity, "pnl");
    const buildBsOptions = getOptions(selectedEntity, "bs");
    const buildPnlOptions = getOptions(selectedEntity, "pnl");
    const fourwayForecastOptions = getOptions(selectedEntity, "forecast");

    const entityData = selectedEntityData()
    const [pnlLayout, setPnlLayout] = useState<IVL>(keysToVLs(entityData["default_pl_layout"])[0]);
    const [bsLayout, setBsLayout] = useState<IVL>(keysToVLs(entityData["default_bs_layout"])[0]);
    const [fourwayForecast, setFourwayForecast] = useState<IVL>(fourwayForecastOptions[0])
    const [buildPnl, setBuildPnl] = useState<IVL>(buildPnlOptions[0])
    const [buildBs, setBuildBs] = useState<IVL>(buildBsOptions[0])

    DataStore.setConfigStore(selectedEntity, {
        pnl: pnlLayout,
        bs: bsLayout,
        pnlOptions: pnlLayoutOptions,
        bsOptions: bsLayoutOptions,
        fourwayForecast: fourwayForecast,
        fourwayForecastOptions: fourwayForecastOptions,
        buildPnl: buildPnl,
        buildPnlOptions: buildPnlOptions,
        buildBs: buildBs,
        buildBsOptions: buildBsOptions
    })

    useEffect(() => {
        const populateRefreshingEntities = async () => {
            const refreshingEntitiesStore = await DataStore.getRefreshingEntitiesStore();
            if (refreshingEntitiesStore.length > 0) {
                let refreshingEntitiesArr = [];
                refreshingEntitiesStore.forEach((refreshEntityInStore) => {
                    refreshingEntitiesArr.push(refreshEntityInStore);
                    pollRefresh(refreshEntityInStore);
                })
                setRefreshingEntities(refreshingEntitiesArr);
            }
        }
        const populateDefaults = async () => {
            const selectedEntityStore = await DataStore.getSelectedEntityStore();
            const entityData = selectedEntityData(selectedEntityStore)
            if (selectedEntityStore && entityOptions.includes(selectedEntity)) {
                setSelectedEntity(selectedEntityStore)
                setPnlLayoutHandler(selectedEntityStore, keysToVLs(entityData["default_pl_layout"])[0])
                setBsLayoutHandler(selectedEntityStore, keysToVLs(entityData["default_bs_layout"])[0])
                setFourwayForecastHandler(selectedEntityStore, entityData["fourway_forecast"].map(value => { return { value: value.name, label: value.name } })[0])
                setBuildPnlHandler(selectedEntityStore, entityData["build_pnl"].map(value => { return { value: value.name, label: value.name } })[0])
                setBuildBsHandler(selectedEntityStore, entityData["build_bs"].map(value => { return { value: value.name, label: value.name } })[0])
            }
        }
        populateRefreshingEntities().catch();
        populateDefaults().catch();
    }
        , []
    )

    const initiateRefresh = async (refreshingEntity: IEntityVL) => {
        try {
            // refreshButtonStyle = { "--p": "0%" } as React.CSSProperties;
            // refreshingEntity.progress = "0";
            const entity = entities.filter(entity => entity.id === refreshingEntity.value)[0]
            const response = await apiProvider.post('company/refresh-data', {
                accounting_provider: entity.accounting_provider_name,
                accounting_provider_company_id: entity.id
            })
            refreshingEntity.job_id = response.job_id;
            await DataStore.addToRefreshingEntitiesStore(refreshingEntity);
            setRefreshingEntities(prevState => [...prevState, refreshingEntity]);
            pollRefresh(refreshingEntity);
        } catch (error) {
            onError("Your session has expired, please login again.")
            onLogout()
        }
    }

    const pollRefresh = (refreshingEntity: IEntityVL) => {
        const poller = setInterval(async () => {
            try {
                let progress = await apiProvider.post('job/progress', {
                    job_id: refreshingEntity.job_id
                });
                if (progress.progress === 1) {
                    DataStore.removeFromRefreshingEntitiesStore(refreshingEntity);
                    setRefreshingEntities(prevState => prevState.filter(entity => entity.value !== refreshingEntity.value))
                    if (progress.error) {
                        onError(progress.error)
                    }
                    refreshCells();
                    clearInterval(poller);
                } else {
                    if (refreshEntity.value === refreshingEntity.value) {
                        setRefreshingEntities(prevValue => { return [...prevValue.filter(entity => entity.value !== refreshingEntity.value), { value: refreshingEntity.value, label: refreshingEntity.label, job_id: refreshingEntity.job_id, progress: progress.progress }] })
                    }
                }
            }
            catch (error) {
                onError("Your session has expired, please login again")
                onLogout()
            }
        }
            , 5000)
    }

    const setSelectedEntityHandler = async (entity: IEntityVL) => {
        await DataStore.setSelectedEntityStore(entity);
        setSelectedEntity(entity);
        const entityData = selectedEntityData(entity)
        setPnlLayoutHandler(entity, keysToVLs(entityData["default_pl_layout"])[0]);
        setBsLayoutHandler(entity, keysToVLs(entityData["default_bs_layout"])[0]);
        setFourwayForecastHandler(entity, entityData["fourway_forecast"].map(value => { return { value: value.name, label: value.name } })[0]);
        setBuildPnlHandler(entity, entityData["build_pnl"].map(value => { return { value: value.name, label: value.name } })[0]);
        setBuildBsHandler(entity, entityData["build_bs"].map(value => { return { value: value.name, label: value.name } })[0]);
    }

    const setPnlLayoutHandler = async (entity: IEntityVL, layout: IVL) => {
        await setConfigStoreHandler(entity, { pnl: layout });
        if (layout) {
            setPnlLayout(layout);
        }
        else {
            setPnlLayout(null);
        }
    }

    const setBsLayoutHandler = async (entity: IEntityVL, layout: IVL) => {
        await setConfigStoreHandler(entity, { bs: layout });
        if (layout) {
            setBsLayout(layout);
        }
        else {
            setBsLayout(null);
        }
    }

    const setFourwayForecastHandler = async (entity: IEntityVL, forecast: IVL) => {
        await setConfigStoreHandler(entity, { fourwayForecast: forecast });
        if (forecast) {
            setFourwayForecast(forecast);
        }
        else {
            setFourwayForecast(null);
        }
    }

    const setBuildPnlHandler = async (entity: IEntityVL, buildPnl: IVL) => {
        await setConfigStoreHandler(entity, { buildPnl: buildPnl });
        if (buildPnl) {
            setBuildPnl(buildPnl);
        }
        else {
            setBuildPnl(null);
        }
    }

    const setBuildBsHandler = async (entity: IEntityVL, buildBs: IVL) => {
        await setConfigStoreHandler(entity, { buildBs: buildBs });
        if (buildBs) {
            setBuildBs(buildBs);
        }
        else {
            setBuildBs(null);
        }
    }

    const setConfigStoreHandler = async (entity: IEntityVL, config: {}) => {
        const configStore = await DataStore.getConfigStore();
        await DataStore.setConfigStore(entity, { ...configStore[selectedEntity.value], ...config });
    }

    const setSettingConfigsHandler = () => {
        setSettingConfigs(prev => !prev);
    }


    if (refreshingEntities) {
        const selectedRefreshingEntity = refreshingEntities.filter(entity => entity.value === refreshEntity.value)[0]
        if (selectedRefreshingEntity) {
            let progress = selectedRefreshingEntity.progress ? (+selectedRefreshingEntity.progress) ** 2 * 100 + "%" : "0%";
            refreshButtonStyle = { "--p": progress, cursor: "default" } as React.CSSProperties;
        }
    }

    let refreshButton: ReactElement;
    if (refreshingEntities.filter(entity => entity.value === refreshEntity.value)[0]) {
        refreshButton = <button className="refresh-progress" style={refreshButtonStyle} disabled={true} >Refreshing...</button>
    } else {
        refreshButton = <button className="small-button" onClick={() => initiateRefresh(refreshEntity)}>Refresh</button>
    }

    const [counter, setCounter] = useState(0);
    const counterHandler = () => {
        setCounter(prev => prev + 1);
        if (counter > 5) {
            setTimeout(() => setCounter(0), 15000)
        }
    }

    let cursorStyle = {
        position: "fixed",
        top: 0,
        cursor: "url(assets/VeryImportantParrot.gif), auto",
        height: "100vh",
        width: "100vw",
        zIndex: 1,
    } as React.CSSProperties;

    return (
        <>
            {settingConfigs && <div className="full-width">
                <h1 className="myaccount-heading">Report Configurations</h1>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="defaultEntitySelector">Select Entity</label>
                    <Select
                        id="defaultEntitySelector"
                        classNamePrefix="new-select-style"
                        value={selectedEntity}
                        options={entityOptions}
                        onChange={setSelectedEntityHandler}
                        isSearchable={true}
                    />
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="pnlLayoutSelector">Profit and Loss Layout</label>
                    <Select
                        id="pnlLayoutSelector"
                        classNamePrefix="new-select-style"
                        value={pnlLayout}
                        options={pnlLayoutOptions}
                        onChange={(value) => setPnlLayoutHandler(selectedEntity, value)}
                        isSearchable={true}
                        placeholder="No Options"
                    />
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="bsLayoutSelector">Balance Sheet Layout</label>
                    <Select
                        id="bsLayoutSelector"
                        classNamePrefix="new-select-style"
                        value={bsLayout}
                        options={bsLayoutOptions}
                        onChange={(value) => setBsLayoutHandler(selectedEntity, value)}
                        isSearchable={true}
                        placeholder="No Options"
                    />
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="buildPnlSelector">{"Build P&L"}</label>
                    <Select
                        id="buildPnlSelector"
                        classNamePrefix="new-select-style"
                        value={buildPnl}
                        options={buildPnlOptions}
                        onChange={(value) => setBuildPnlHandler(selectedEntity, value)}
                        isSearchable={true}
                        placeholder="No Options"
                    />
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="buildBsSelector">{"Build BS"}</label>
                    <Select
                        id="buildBsSelector"
                        classNamePrefix="new-select-style"
                        value={buildBs}
                        options={buildBsOptions}
                        onChange={(value) => setBuildBsHandler(selectedEntity, value)}
                        isSearchable={true}
                        placeholder="No Options"
                    />
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="fourwayForecastSelector">Fourway Forecast</label>
                    <Select
                        id="fourwayForecastSelector"
                        classNamePrefix="new-select-style"
                        value={fourwayForecast}
                        options={fourwayForecastOptions}
                        onChange={(value) => setFourwayForecastHandler(selectedEntity, value)}
                        isSearchable={true}
                        placeholder="No Options"
                    />
                </div>
                <div className="myaccount-field">
                    <button className="small-button" type="button" onClick={refreshCells}>Refresh Cells</button>
                </div>
                <button className="small-button" type="button" onClick={setSettingConfigsHandler}>Back</button>
            </div>}
            {!settingConfigs && <div className="full-width">
                <h1 className="myaccount-heading">{`Welcome, ${loginStatus["first_name"]}.`}</h1>
                <h1 className="myaccount-heading">You are logged in<a style={{ cursor: "default" }} onClick={counterHandler}>!</a></h1>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="username">User name</label>
                    <input className="myaccount-value" type="text" readOnly={true} id="username" value={`${loginStatus["first_name"]} ${loginStatus["last_name"]}`} />
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="email">Email address</label>
                    <input className="myaccount-value" type="text" readOnly={true} id="email" value={loginStatus["email"]} />
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="plan">Plan</label>
                    <input className="myaccount-value" type="text" readOnly={true} id="plan" value={plansMapping[loginStatus["plan"]]} />
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="defaultEntitySelector">Select Entity</label>
                    <Select
                        id="defaultEntitySelector"
                        classNamePrefix="new-select-style"
                        value={selectedEntity}
                        options={entityOptions}
                        onChange={setSelectedEntityHandler}
                        isSearchable={true}
                    />
                    <button className="small-button" type="button" onClick={setSettingConfigsHandler}>Report Configurations</button>
                </div>
                <button className="small-button" type="button" onClick={refreshCells}>Refresh Cells</button>
                <div className="myaccount-field-goto">
                    <label className="myaccount-label" htmlFor="goto">Go to</label>
                    <ul className="myaccount-list" id="goto">
                        <li><a className="myaccount-link" href="http://www.syftanalytics.com/">Syft</a></li>
                        <li><a className="myaccount-link" href="http://www.syftanalytics.com/knowledge-center" >Knowledge Center</a></li>
                    </ul>
                </div>
                <div className="myaccount-field">
                    <label className="myaccount-label" htmlFor="entityRefreshSelector">Refresh Entity</label>
                    <Select
                        id="entityRefreshSelector"
                        classNamePrefix="new-select-style"
                        value={refreshEntity}
                        options={refreshOptions}
                        onChange={setRefreshEntity}
                        isSearchable={true}
                    />
                    {refreshButton}
                </div>
                <button className="big-button" type="button" onClick={onLogout}>Logout</button>
            </div>}
            <div style={counter >= 10 ? cursorStyle : {}}></div>
        </>
    )
}
export default MyAccount;