import React, { useEffect, useState } from "react";
import "!style-loader!css-loader!./LoginPage.css";
import ApiProvider from "../../api/ApiProvider";
import { Spinner } from "@fluentui/react";

interface ILogin {
    endpoint: string,
    apiProvider: ApiProvider,
    username: string,
    setUsername: (username: string) => void,
    password: string,
    setPassword: (password: string) => void,
    keepMeLoggedIn: boolean,
    setKeepMeLoggedIn: () => void,
    submitHandler: (event: React.FormEvent) => void,
    setSso: (sso: string) => void,
}

const ssoClick = async (props: { endpoint: string, apiProvider: ApiProvider, provider: string, setSso: (sso: string) => void }) => {

    let result = await props.apiProvider.get(`user/sso-sign-in-link?sso_provider=${props.provider}`);

    const state = result.state;
    const link = result.url;
    const host = props.endpoint.replace("api/v1", "") // .replace("8001","8000")
    const ssoUrl = host + link;
    open(ssoUrl)
    props.setSso(state)
}

const LoginPage: React.FC<ILogin>
    = ({ endpoint, apiProvider, username, setUsername, password, setPassword, keepMeLoggedIn, setKeepMeLoggedIn, submitHandler, setSso }) => {
        const [svgLoaded, setSvgLoaded] = useState({ xero: false, intuit: false, google: false })
        const [isLoaded, setIsLoaded] = useState(false);

        useEffect(() => {
            if (Object.values(svgLoaded).every(value => value === true)) {
                setIsLoaded(true);
            }
        }, [svgLoaded])

        const svgLoadHandler = (provider: string) => {
            setSvgLoaded(prev => { return { ...prev, [provider]: true } })
        }

        return (
            <>
                {!isLoaded && <div></div>}
                {!isLoaded && <div><Spinner size={3} /></div>}
                <div style={isLoaded ? {} : { display: "none" }}>
                    <h1 className="heading">Welcome to Syft Analytics for Excel</h1>
                    <div className="sso-login-button-wrapper">
                        <img src="assets/SSO_Xero.svg" onLoad={svgLoadHandler.bind(null, "xero")} className="sso-login-button" onClick={() => ssoClick({ endpoint: endpoint, apiProvider: apiProvider, provider: "xero", setSso: setSso })} />
                        <img src="assets/SSO_Intuit.svg" onLoad={svgLoadHandler.bind(null, "intuit")} className="sso-login-button" onClick={() => ssoClick({ endpoint: endpoint, apiProvider: apiProvider, provider: "quickbooks", setSso: setSso })} />
                        <img src="assets/SSO_Google.svg" onLoad={svgLoadHandler.bind(null, "google")} className="sso-login-button" onClick={() => ssoClick({ endpoint: endpoint, apiProvider: apiProvider, provider: "google", setSso: setSso })} />
                    </div>
                    <div className="login-heading-2-provider">
                        <hr />
                        <div style={{ margin: "0px 9px" }}>
                            or, sign in with your email
                        </div>
                        <hr />
                    </div>
                    <form className="login-form" onSubmit={submitHandler}>
                        <input type="text" id="username" placeholder="Email" value={username} onChange={event => { setUsername(event.target.value) }} />
                        <input type="password" id="password" placeholder="Password" value={password} onChange={event => { setPassword(event.target.value) }} />
                        <div className="login-forgot-pass-wrapper">
                            <div className="checkbox-wrapper">
                                <label className="container checkbox-text">Keep me logged in
                                    <input type="checkbox" checked={keepMeLoggedIn} onChange={() => { setKeepMeLoggedIn() }} />
                                    <span className="mark"></span>
                                </label>
                            </div>
                            <a className="forgot-password-link" href="http://syftanalytics.com/forgot-password">Forgot password</a>
                        </div>
                        <div className="login-button-wrapper">
                            <button className="login-button big-button" type="submit">Login</button>
                            <div className="text">
                                Don't have an account, <a className="link" href="http://syftanalytics.com/sign-up">sign up</a> now
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }

export default LoginPage;