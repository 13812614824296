import React from "react";

import "!style-loader!css-loader!./ErrorPage.css";

const ErrorPage = ({ isError, setIsError, outdated, suspiciousActivity, setRequireReauthentication }) => {
    let button = <button className="big-button" onClick={setIsError.bind(null, null)}>Try again</button>
    if (outdated) {
        button = <button className="big-button" onClick={() => open(outdated)} >Update Now</button>
    }
    if (suspiciousActivity) {
        button = <button className="big-button" onClick={setRequireReauthentication} >Enter verification code</button>
    }
    return (
        <div>
            <h1 className="error-heading" >{isError}</h1>
            {button}
        </div>
    )
}

export default ErrorPage;