import * as React from "react";
import { useState } from "react";
import "!style-loader!css-loader!./FirstRunPage.css";

const FirstRunPage: React.FC<{ setFirstRunHandler: () => void }> = ({ setFirstRunHandler }) => {
    const [pageCount, setPageCount] = useState<number>(1);

    const increasePageCount = () => {
        setPageCount(value => value + 1);
    }

    const decreasePageCount = () => {
        setPageCount(value => value - 1);
    }

    const setPageCountHandler = (page: number) => {
        setPageCount(page);
    }

    const IncreaseButton = () => {
        return (
            <button className="nav-button right" onClick={increasePageCount}><span className="chevron right"></span></button>
        )
    }

    const DecreaseButton = () => {
        return (
            <button className="nav-button left" onClick={decreasePageCount}><span className="chevron left"></span></button>
        )
    }

    const SkipButton = () => {
        return (
            <button className="skip-button" onClick={setFirstRunHandler}>Skip <span className="chevron right"></span></button>
        )
    }

    const PageButton = ({ selected, onClick }) => {
        return (
            <button className={`page-button ${selected && "selected"}`} onClick={onClick} />
        )
    }

    const PageTracker = ({ page, setPage, numPages }) => {

        const pageButtons = [];
        for (let index = 1; index <= numPages; index++) {
            pageButtons.push(
                <PageButton
                    key={index}
                    selected={page === index}
                    onClick={setPage.bind(null, index)}
                />
            )
        }

        return (
            <div className="page-tracker" style={{ width: 15 * (numPages + 1) }}>
                {...pageButtons}
            </div>
        )
    }

    const PageOne = () => {
        return (
            <div>
                <div className="fre-skip">
                    <SkipButton />
                </div>
                <div className="fre-grey">
                    <img src={"https://excel.syftanalytics.com/v1.1.2/assets/First-run-experience-01.svg"} />
                </div>
                <div className="fre-body">
                    <h2>Your data in Excel</h2>
                    <p>Connect your accounting and<br />e-commerce data directly to Excel with<br />your Syft Analytics account.</p>
                </div>
                <IncreaseButton />
            </div>
        )
    }

    const PageTwo = () => {
        return (
            <div>
                <div className="fre-skip">
                    <SkipButton />
                </div>
                <div className="fre-grey">
                    <img src={"https://excel.syftanalytics.com/v1.1.2/assets/First-run-experience-02.svg"} />
                </div>
                <div className="fre-body">
                    <h2>Every report from<br />Syft Analytics</h2>
                    <p>Link all your favorite Syft reports and insights<br />into Excel so they’re always up-to-date.</p>
                </div>
                <IncreaseButton />
                <DecreaseButton />
            </div>
        )
    }

    const PageThree = () => {
        return (
            <>
                <div className="fre-body">
                    <img src={"https://excel.syftanalytics.com/v1.1.2/assets/Syft-Logo.svg"} />
                    <h1>Welcome</h1>
                    <h2>Syft Analytics for Excel</h2>
                </div>
                <div className="fre-grey welcome">
                    <ul className="fre-list-1"><li>Connect your data to Excel</li></ul>
                    <ul className="fre-list-2"><li>Link your reports to Excel</li></ul>
                </div>
                <div className="fre-spacer"></div>
                <button className="big-button fre" onClick={setFirstRunHandler}>Sign in</button>
                <DecreaseButton />
            </>
        )
    }

    let content;
    switch (pageCount) {
        case 1:
            content = <PageOne />
            break;
        case 2:
            content = <PageTwo />
            break;
        case 3:
            content = <PageThree />
            break;
        default:
            content = <PageOne />
    }

    return (
        <>
            {content}
            <PageTracker page={pageCount} setPage={setPageCountHandler} numPages={3} />
        </>
    )
}

export default FirstRunPage;